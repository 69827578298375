import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InstagramService } from 'src/app/services/instagram.service';
import { Router } from '@angular/router';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'disconnect-instagram-account',
  templateUrl: './disconnect-instagram-account.component.html',
  styleUrls: ['./disconnect-instagram-account.component.css']
})
/** google-analytics-accounts component*/
export class DisconnectInstagramAccountComponent {
  constructor(
    public dialogRef: MatDialogRef<DisconnectInstagramAccountComponent>,
    private instaService : InstagramService,
    private router : Router,
    private spinner : NgxSpinnerService,
    // private dashboardComponent : DashboardComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
    
  }

  onConfirm() : void {
    this.spinner.show('disconnectSpinner');
    this.instaService.disconnectInstagramAccount().subscribe((res : any) =>{
      this.dialogRef.close();
      location.reload();
    })
  }

}
