import { Component, OnInit } from '@angular/core';
import { CreatorProfileService } from '../services/creator-profile.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  private creatorProfile: Object;

  constructor(
    private creatorProfileService: CreatorProfileService,
    private route: ActivatedRoute,
    private spinner : NgxSpinnerService
  ) {

  }
  ngOnInit() {
    this.spinner.show('dashboardSpinner');
    this.creatorProfileService.getCreatorProfile()
      .subscribe((response) => {
        if (response.status == 200) {
          this.creatorProfile = response.body;
          this.spinner.hide('dashboardSpinner');
        }
      })
  }
}
