import { ValidationOutput } from "./validation-output";
import { PasswordStrength } from "./password-strength";
import { Constants } from "../constants";

const strongRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
const mediumRegex: RegExp = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

export class PasswordValidation extends ValidationOutput {
  passwordStrength: PasswordStrength;

  constructor(_password: string) {
    super();
    this.validate(_password);
  }

  validate(password: string): void {
    if (password.length == 0) {
      this.passwordStrength = PasswordStrength.Blank;
      this.message = "Password cannot be blank";
      this.status = false;
    }
    else if (password.length < Constants.MINIMUM_PASSWORD_LENGTH) {
      this.passwordStrength = PasswordStrength.Blank;
      this.message = "Password too short < 6 characters";
      this.status = false;
    }
    else if (strongRegex.test(password)) {
      this.passwordStrength = PasswordStrength.Strong;
      this.message = "Strong Password";
      this.status = true;
    }
    else if (mediumRegex.test(password)) {
      this.passwordStrength = PasswordStrength.Medium;
      this.message = "Medium Password";
      this.status = true;
    }
    else {
      this.passwordStrength = PasswordStrength.Weak;
      this.message = "Easy Password";
      this.status = true;
    }
  }
}
