import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram-access-token-app',
  templateUrl: './instagram-access-token-app.component.html',
  styleUrls: ['./instagram-access-token-app.component.css']
})
export class InstagramAccessTokenAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let interval = setInterval(function () {
      // Check if hash exists
      if (window.location.hash && window.location.hash.length) {
        // Hash found, that includes the access token
        clearInterval(interval);
        sessionStorage.setItem('token', window.location.hash.slice(14, window.location.hash.indexOf("&data_access_expiration_time")));
        //window.close();
      }
    }, 100);
  }

}
