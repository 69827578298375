import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Constants } from '../constants';
import { ModalData } from '../models/modal-data';
import { PlatformEnum } from '../models/platform-enum';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { InstagramAccountsComponent } from '../common/confirmation-dialog/instagram-accounts/instagram-accounts.component';

import { AuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable()
export class InstagramService {
  private readonly accountsQuery = 'me?fields=accounts{name,connected_instagram_account,location},location&access_token=';
  private facebookPagesUrl : string = "/accounts?fields=id,name,access_token,instagram_business_account&access_token=";

  private modalData: ModalData;
  public userInfo = new BehaviorSubject(null);
  public accounts = new BehaviorSubject(null);
  public account = new BehaviorSubject(null);

  constructor(
    private dialogService: MatDialog,
    private authService: AuthService,
    private http: HttpClient,
    private sessionService : SessionService
  ) {
    this.authService.authState.subscribe((user: SocialUser) => {
      this.userInfo.next(user);
    });
    if (this.account.observers.length == 0) {
      this.accounts.subscribe((acnts: Array<any>) => {
        if (acnts) {
          if (acnts.length === 0) {
            this.modalData = new ModalData(
              "Oops! No Accounts Found.",
              "We could not find any Facebook Page with a Connected Instagram Account.",
              "Okay"
            );
            this.modalData.buttonPositiveHandler = () => { return; };

            this.dialogService.open(ConfirmationDialogComponent, {
              autoFocus: false,
              data: this.modalData
            });
          } else {
            this.showAccountsDialog(acnts);
          }
        }
      });
    }
  }

  public fetchAccounts(user: SocialUser): void {
    if (user && user.id && user.id != '') {
      this.http.get(Constants.FB_LOGIN_OPTIONS.graph_api_url + this.accountsQuery + user.authToken).subscribe((data) => {
        if (data && data["accounts"] && data["accounts"]["data"] && data["accounts"]["data"].length > 0) {
          let accounts: Array<any> = [];
          data["accounts"]["data"].forEach(a => {
            if (a["connected_instagram_account"]) {
              accounts.push({
                id: a["connected_instagram_account"]["id"],
                name: a["name"],
                location: a["location"] ? a["location"]["city"] + ", " + a["location"]["country"] : data["location"] ? data["location"]["name"] : ""
              });
            }
          });
          if (accounts.length > 0) {
            this.accounts.next(accounts);
          }
        }
      });
    }
  }

  public showAccountsDialog(accounts: Array<any>): void {
    let accountsDialog = this.dialogService.open(InstagramAccountsComponent, {
      data: accounts
    });
    if (accountsDialog.componentInstance.account.observers.length == 0) {
      accountsDialog.componentInstance.account.subscribe((account) => {
        if (account && account.id && account.id != '') {
          this.account.next(account);
          accountsDialog.close();
        }
      });
    }
  }

  public showConnectDialog(): void {
    this.modalData = new ModalData(
      "Verify Instagram Account",
      "We need to ensure that you have a valid Facebook Page associated with your Business Instagram Account.",
      "Connect With Instagram"
    );
    this.modalData.platform = PlatformEnum.Instagram;

    this.modalData.buttonPositiveHandler = () => {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    };

    this.dialogService.open(ConfirmationDialogComponent, {
      autoFocus: false,
      data: this.modalData
    });
  }

  public signOut() {
    if (this.userInfo.value) {
      this.userInfo.next(null);
      return this.authService.signOut();
    }
    else
      return new Promise<any>((resolve, reject) => { });
  }

  public getInstagramAccountsOfUser(data : any){

      if(data != null){
        return this.http.get(Constants.FB_LOGIN_OPTIONS.graph_api_url + data.id + this.facebookPagesUrl + data.authToken);
      }  
  }

  public addInstagramAccount(instagramUserInfo : any) : any{
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-type' : 'application/json',
        'Authorization' : 'Bearer ' + this.sessionService.get("token")
      })
    }
    return this.http.post(environment.webApiUrl.toString() + '/api/ConnectChannel/Instagram', instagramUserInfo, httpOptions);
  }

  public disconnectInstagramAccount(){
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-type' : 'application/json',
        'Authorization' : 'Bearer ' + this.sessionService.get("token")
      })
    }
    return this.http.delete(environment.webApiUrl.toString() + '/api/DisconnectChannel/Instagram', httpOptions);
  }

}
