export enum ELanguageNames {
    unassigned = 0,
    Afar = 1,
    Abkhazian = 2,
    Avestan = 3,
    Afrikaans = 4,
    Akan = 5,
    Amharic = 6,
    Aragonese = 7,
    Arabic = 8,
    Assamese = 9,
    Avaric = 10,
    Aymara = 11,
    Azerbaijani = 12,
    Bashkir = 13,
    Belarusian = 14,
    Bulgarian = 15,
    Bihari = 16,
    Bislama = 17,
    Bambara = 18,
    Bengali = 19,
    Tibetan = 20,
    Breton = 21,
    Bosnian = 22,
    Catalan = 23,
    Chechen = 24,
    Chamorro = 25,
    Corsican = 26,
    Cree = 27,
    Czech = 28,
    Church_Slavic = 29,
    Chuvash = 30,
    Welsh = 31,
    Danish = 32,
    German = 33,
    Divehi = 34,
    Dzongkha = 35,
    Ewe = 36,
    Greek = 37,
    English = 38,
    Esperanto = 39,
    Spanish = 40,
    Estonian = 41,
    Basque = 42,
    Persian = 43,
    Fulah = 44,
    Finnish = 45,
    Fijian = 46,
    Faroese = 47,
    French = 48,
    Western_Frisian = 49,
    Irish = 50,
    Gaelic = 51,
    Galician = 52,
    Guarani = 53,
    Gujarati = 54,
    Manx = 55,
    Hausa = 56,
    Hebrew = 57,
    Hindi = 58,
    Hiri_Motu = 59,
    Croatian = 60,
    Haitian = 61,
    Hungarian = 62,
    Armenian = 63,
    Herero = 64,
    Interlingua = 65,
    Indonesian = 66,
    Interlingue = 67,
    Igbo = 68,
    Sichuan_Yi = 69,
    Inupiaq = 70,
    Ido = 71,
    Icelandic = 72,
    Italian = 73,
    Inuktitut = 74,
    Japanese = 75,
    Javanese = 76,
    Georgian = 77,
    Kongo = 78,
    Kikuyu = 79,
    Kuanyama = 80,
    Kazakh = 81,
    Kalaallisut = 82,
    Central_Khmer = 83,
    Kannada = 84,
    Korean = 85,
    Kanuri = 86,
    Kashmiri = 87,
    Kurdish = 88,
    Komi = 89,
    Cornish = 90,
    Kirghiz = 91,
    Latin = 92,
    Luxembourgish = 93,
    Ganda = 94,
    Limburgan = 95,
    Lingala = 96,
    Lao = 97,
    Lithuanian = 98,
    Luba_Katanga = 99,
    Latvian = 100,
    Malagasy = 101,
    Marshallese = 102,
    Maori = 103,
    Macedonian = 104,
    Malayalam = 105,
    Mongolian = 106,
    Marathi = 107,
    Malay = 108,
    Maltese = 109,
    Burmese = 110,
    Nauru = 111,
    Bokmal_Norwegian = 112,
    Ndebele_North = 113,
    Nepali = 114,
    Ndonga = 115,
    Dutch = 116,
    Norwegian_Nynorsk = 117,
    Norwegian = 118,
    Ndebele_South = 119,
    Navajo = 120,
    Nyanja = 121,
    Occitan = 122,
    Ojibwa = 123,
    Oromo = 124,
    Oriya = 125,
    Ossetian = 126,
    Punjabi = 127,
    Pali = 128,
    Polish = 129,
    Pushto = 130,
    Portuguese = 131,
    Quechua = 132,
    Romansh = 133,
    Rundi = 134,
    Romanian = 135,
    Russian = 136,
    Kinyarwanda = 137,
    Sanskrit = 138,
    Sardinian = 139,
    Sindhi = 140,
    Northern_Sami = 141,
    Sango = 142,
    Sinhalese = 143,
    Slovak = 144,
    Slovenian = 145,
    Samoan = 146,
    Shona = 147,
    Somali = 148,
    Albanian = 149,
    Serbian = 150,
    Swati = 151,
    Sotho = 152,
    Sundanese = 153,
    Swedish = 154,
    Swahili = 155,
    Tamil = 156,
    Telugu = 157,
    Tajik = 158,
    Thai = 159,
    Tigrinya = 160,
    Turkmen = 161,
    Tagalog = 162,
    Tswana = 163,
    Tonga = 164,
    Turkish = 165,
    Tsonga = 166,
    Tatar = 167,
    Twi = 168,
    Tahitian = 169,
    Uighur = 170,
    Ukrainian = 171,
    Urdu = 172,
    Uzbek = 173,
    Venda = 174,
    Vietnamese = 175,
    Volapük = 176,
    Walloon = 177,
    Wolof = 178,
    Xhosa = 179,
    Yiddish = 180,
    Yoruba = 181,
    Zhuang = 182,
    Chinese = 183,
    Zulu = 184
}
