import { Component, OnInit, Input } from '@angular/core';
import { EGender } from '../../../RefEnums/egender.enum';

@Component({
  selector: 'app-gender-graph',
  templateUrl: './gender-graph.component.html',
  styleUrls: ['./gender-graph.component.css']
})
export class GenderGraphComponent implements OnInit {

  @Input() insights : any;

  genderInsights = new Array();
  view : any[] = [125, 200];
  legendPosition = "right";
  colorScheme = {
    domain: ['#003f5c', '#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600']
  };
  
  constructor() { }

  ngOnInit() {
    // data format
    // {
    //   "name": "Gender",
    //   "series": [
    //     {
    //       "name": "Male",
    //       "value": 82.42
    //     },
    //     {
    //       "name": "Female",
    //       "value": 17.57
    //     },
    //     {
    //       "name": "Other",
    //       "value": 0.0
    //     }
    //   ]
    // }
    var data = new Object();
    var series = new Array();
    data["name"] = "Gender"
    this.insights[0].creatorAudienceGender.forEach((element)=>{
      var seriesData = new Object();
      seriesData["name"] = EGender[element["genders"]];
      seriesData["value"] = Number((element["genderPct"]).toFixed(1));
      series.push(seriesData);
    });
    data["series"] = series;
    this.genderInsights.push(data);
  }

}
