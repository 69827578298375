import { Component } from '@angular/core';

@Component({
    selector: 'privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.css']
})
/** privacy component*/
export class PrivacyComponent {
    /** privacy ctor */
    constructor() {

    }
}