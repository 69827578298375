import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ModalData } from '../../models/modal-data';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { GoogleUser } from '../../models/google-user';
import { MatDialog, MatTooltip } from '@angular/material';
import { PlatformEnum } from '../../models/platform-enum';

import { ProfileNumbersComponent } from '../../home/profile-numbers/profile-numbers.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

import { GoogleService } from '../../services/google.service';
import { FacebookService } from '../../services/facebook.service';
import { InstagramService } from '../../services/instagram.service';
import { CommonUtilService } from '../../services/common-util.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'url-input',
  templateUrl: './url-input.component.html',
  styleUrls: ['./url-input.component.css']
})

/** url-input component*/
export class UrlInputComponent implements OnInit, OnDestroy {
  platformHTML: string = "Platform";
  formdata: FormGroup;
  url: string;
  public platform: PlatformEnum;
  exampleUrlPlaceholder: string = "https://www.instagram.com/iamsrk/";

  private readonly twitterApiUrl = environment.webApiUrl + "/api/numbers/twitter/";
  private readonly youtubeApiUrl = environment.webApiUrl + "/api/numbers/youtube/";
  private readonly facebookApiUrl: string = environment.webApiUrl + "/api/numbers/facebook/";
  private readonly blogApiUrl: string = environment.webApiUrl + "/api/numbers/blog/";
  private readonly instagramApiUrl: string = environment.webApiUrl + "/api/numbers/instagram/";
  private readonly instagramExampleUrl = "https://www.instagram.com/iamsrk/";
  private readonly youtubeExampleUrl = "https://www.youtube.com/channel/UCQJTzCJD3HaB7NOc9gnd3bg";
  private readonly facebookExampleUrl = "https://www.facebook.com/motoroids/";
  private readonly blogExampleUrl = "https://lookwhostalking.co.in";
  private readonly twitterExampleUrl = "https://twitter.com/iamsrk";

  @ViewChild(MatTooltip, null) platformTooltip;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog,
    private spinner: NgxSpinnerService,
    private googleService: GoogleService,
    private commonUtil: CommonUtilService,
    private facebookService: FacebookService,
    private instagramService: InstagramService) {
  }

  ngOnInit() {
    this.selectPlatform(PlatformEnum.NotSelected);
    this.url = "";
  }

  validate(): boolean {
    return this.commonUtil.validateUrl(this.url) || (this.url.length > 2 && this.platform != PlatformEnum.NotSelected)
  }

  selectPlatform(id: PlatformEnum): void {
    switch (id) {
      case PlatformEnum.YouTube:
        this.platformHTML = "<img src='../../../assets/images/social-youtube.png' />";
        this.exampleUrlPlaceholder = this.youtubeExampleUrl;
        break;
      case PlatformEnum.Twitter:
        this.platformHTML = "<img src='../../../assets/images/social-twitter.png' />";
        this.exampleUrlPlaceholder = this.twitterExampleUrl;
        break;
      case PlatformEnum.Blog:
        this.platformHTML = "<img src='../../../assets/images/social-blog.png' />";
        this.exampleUrlPlaceholder = this.blogExampleUrl;
        break;
      case PlatformEnum.Instagram:
        this.platformHTML = "<img src='../../../assets/images/social-instagram.png' />";
        this.exampleUrlPlaceholder = this.instagramExampleUrl;
        break;
      case PlatformEnum.Facebook:
        this.platformHTML = "<img src='../../../assets/images/social-facebook.png' />";
        this.exampleUrlPlaceholder = this.facebookExampleUrl;
        break;
      default:
        this.platformHTML = "Platform";
        this.exampleUrlPlaceholder = this.instagramExampleUrl;
        break;
    }
    this.platform = id;
  }

  urlChanged(): void {
    if (this.commonUtil.validateUrl(this.url)) {
      if (this.url.includes("instagram.com")) {
        this.selectPlatform(PlatformEnum.Instagram);
      } else if (this.url.includes("twitter.com")) {
        this.selectPlatform(PlatformEnum.Twitter);
      } else if (this.url.includes("facebook.com")) {
        this.selectPlatform(PlatformEnum.Facebook);
      } else if (this.url.includes("youtube.com")) {
        this.selectPlatform(PlatformEnum.YouTube);
      } else {
        this.selectPlatform(PlatformEnum.Blog);
      }
      this.platformTooltip.hide();
    } else {
      this.selectPlatform(PlatformEnum.NotSelected);
      this.platformTooltip.show();
    }
  }

  showNumbers() {
    this.platformTooltip.hide();
    if (this.validate()) {
      switch (this.platform) {
        case PlatformEnum.Twitter:
          this.spinner.show('numbersSpinner');
          this.http
            .get(this.twitterApiUrl, {
              params: {
                "url": this.url
              }
            }).subscribe(profile => {
              if (profile) {
                let numbersDialog = this.dialogService.open(ProfileNumbersComponent, {
                  data: { profile: profile, platform: this.platform }
                });
                numbersDialog.afterClosed().subscribe(result => {
                  this.dialogService.closeAll();
                });
              } else {
                this.dialogService.open(ConfirmationDialogComponent, {
                  data: { header: "Error", message: "An invalid profile url/handle was passed as an input.", buttonPositive: "Okay" }
                });
              }
              this.spinner.hide('numbersSpinner');
            }, error => {
              this.dialogService.open(ConfirmationDialogComponent, {
                data: { header: "Error", message: "An error occurred fetching twitter details. Contact Tech Support for more details.", buttonPositive: "Okay" }
              });

              this.spinner.hide('numbersSpinner');
            });
          break;
        case PlatformEnum.YouTube:
          this.spinner.show('numbersSpinner');
          this.http.get(this.youtubeApiUrl, {
            params: {
              url: this.url
            }
          }).subscribe(profile => {
            if (profile) {
              let numbersDialog = this.dialogService.open(ProfileNumbersComponent, {
                data: { profile: profile, platform: this.platform }
              });
              numbersDialog.afterClosed().subscribe(result => {
                this.dialogService.closeAll();
              });
            } else {
              let modalData: ModalData = new ModalData(
                "Error",
                "An invalid profile url/handle was passed as an input.",
                "Okay",
                null);

              this.dialogService.open(ConfirmationDialogComponent, {
                data: modalData
              });
            }
            this.spinner.hide('numbersSpinner');
          }, error => {
            let modalData: ModalData = new ModalData(
              "Error",
              "An invalid profile url/handle was passed as an input.",
              "Okay",
              null);

            this.dialogService.open(ConfirmationDialogComponent, {
              data: { header: "Error", message: "An error occurred fetching youtube details. Contact Tech Support for more details.", buttonPositive: "Okay" }
            });

            this.spinner.hide('numbersSpinner');
          });
          break;
        case PlatformEnum.Facebook:
          this.facebookService.signOut();
          // this timeout is to ensure that the user is successfully logged out before subscribing to any of userinfo observers
          setTimeout(() => {
            this.facebookService.showConnectDialog();
            if (this.facebookService.userInfo.observers.length == 0) {
              this.facebookService.userInfo.subscribe((user) => {
                if (user && user["id"] && user["id"] != "" && user.provider == "FACEBOOK") {
                  this.spinner.show('numbersSpinner');
                  this.http.get(this.facebookApiUrl, {
                    params: {
                      accessToken: user["authToken"].toString(),
                      url: this.commonUtil.getHandleFromUrl(this.url)
                    }
                  }).subscribe(profile => {
                    if (profile) {
                      let numbersDialog = this.dialogService.open(ProfileNumbersComponent, {
                        data: { profile: profile, platform: this.platform }
                      });
                      numbersDialog.afterClosed().subscribe(result => {
                        this.dialogService.closeAll();
                        this.facebookService.signOut();
                        this.facebookService.userInfo.unsubscribe();
                      });
                    } else {
                      let modalData: ModalData = new ModalData(
                        "Error",
                        "An invalid profile as the .",
                        "Okay",
                        null);

                      this.dialogService.open(ConfirmationDialogComponent, {
                        data: modalData
                      });
                    }
                    this.spinner.hide('numbersSpinner');
                  }, error => {
                    let modalData: ModalData = new ModalData(
                      "Error",
                      "An invalid profile url/handle was passed as an input.",
                      "Okay",
                      null);
                    modalData.buttonPositiveHandler = () => { return; }
                    this.dialogService.open(ConfirmationDialogComponent, {
                      data: { header: "Error", message: "An error occurred fetching facebook details. Contact Tech Support for more details.", buttonPositive: "Okay" }
                    });
                    this.spinner.hide('numbersSpinner');
                  }
                  );
                }
              });
            }
          }, 1000);
          break;
        case PlatformEnum.Blog:
          this.googleService.signOut();
          // this timeout is to ensure that the user is successfully logged out before subscribing to any of userinfo observers
          setTimeout(() => {
            this.googleService.showConnectDialog();
            if (this.googleService.userInfo.observers.length == 0) {
              this.googleService.userInfo.subscribe((user: GoogleUser) => {
                if (user && user.id && user.id != "" && user.provider == "GOOGLE") {
                  this.googleService.showAccountDialog(user, this.url);
                  if (this.googleService.viewId.observers.length == 0) {
                    this.googleService.viewId.subscribe((viewId) => {
                      if (viewId != '') {
                        this.spinner.show('numbersSpinner');
                        this.http.get(this.blogApiUrl, {
                          params: {
                            viewId: viewId,
                            accessToken: user.authToken
                          }
                        }).subscribe(numbers => {
                          if (numbers && numbers["reach"] != 0) {
                            let numbersDialog = this.dialogService.open(ProfileNumbersComponent, {
                              data: {
                                profile: user,
                                numbers: numbers,
                                platform: this.platform,
                                url: this.url
                              }
                            });
                            numbersDialog.afterClosed().subscribe(result => {
                              this.dialogService.closeAll();
                              this.googleService.signOut();
                              this.googleService.userInfo.unsubscribe();
                            });
                          }
                          this.spinner.hide('numbersSpinner');
                        });
                      }
                    });
                  }
                }
              });
            }
          }, 1000);
          break;
        case PlatformEnum.Instagram:
          this.instagramService.signOut();
          // this timeout is to ensure that the user is successfully logged out before subscribing to any of userinfo observers
          setTimeout(() => {
            this.instagramService.showConnectDialog();
            if (this.instagramService.userInfo.observers.length == 0) {
              this.instagramService.userInfo.subscribe((user) => {
                if (user && user.id && user.id != "" && user.provider == "FACEBOOK") {
                  this.instagramService.fetchAccounts(user);
                  if (this.instagramService.account.observers.length == 0) {
                    this.instagramService.account.subscribe((account) => {
                      if (account && account.id && account.id != '') {
                        this.spinner.show('numbersSpinner');
                        this.http.get(this.instagramApiUrl, {
                          params: {
                            accountId: account.id,
                            accessToken: user.authToken,
                            url: this.url
                          }
                        }).subscribe(profile => {
                          if (profile) {
                            profile["location"] = account.location;
                            let numbersDialog = this.dialogService.open(ProfileNumbersComponent, {
                              data: {
                                profile: profile,
                                platform: this.platform,
                                url: this.url
                              }
                            });
                            numbersDialog.afterClosed().subscribe(result => {
                              this.dialogService.closeAll();
                              this.instagramService.signOut();
                              this.instagramService.userInfo.unsubscribe();
                            });
                          }
                          this.spinner.hide('numbersSpinner');
                        }, error => {
                          let modalData: ModalData = new ModalData(
                            "Error",
                            "An invalid profile url/handle was passed as an input.",
                            "Okay",
                            null);
                          modalData.buttonPositiveHandler = () => { return; }
                          this.dialogService.open(ConfirmationDialogComponent, {
                            data: { header: "Error", message: "An error occurred fetching instagram details. Ensure you're authorizing the correct instagram account linked page. Contact Tech Support for more details.", buttonPositive: "Okay" }
                          });
                          this.spinner.hide('numbersSpinner');
                        });
                      }
                    });
                  }
                }
              });
            }
          }, 1000);
          break;
        default:
          break;
      }

    }
  }

  ngOnDestroy() {
    this.facebookService.userInfo.unsubscribe();
    this.googleService.userInfo.unsubscribe();
    this.googleService.viewId.unsubscribe();
    this.instagramService.userInfo.unsubscribe();
    this.instagramService.account.unsubscribe();
  }
}
