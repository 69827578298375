import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ContactComponent } from '../../contact/contact.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
/** footer component*/
export class FooterComponent {
  /** footer ctor */
  constructor(private dialogService: MatDialog) { }

  openContactDialog() {
    this.dialogService.open(ContactComponent);
  }
}
