import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SignupDTO } from '../models/signup-dto';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  user: object;
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  userSignup(user: Object) {
    return this.http.post(environment.webApiUrl + "/api/ApplicationUser/Register", user, { observe: 'response' });
  }

  isLoggedIn(): boolean {
    if (this.sessionService.get("token")) {
      return true;
    }
    else {
      return false;
    }
  }

  userLogin(login: Object) {
    return this.http.post(environment.webApiUrl + "/api/ApplicationUser/Login", login, { observe: 'response' });
  }

  logoutUser() {
    if (this.isLoggedIn()) {
      this.sessionService.remove("token");
    }
  }
}
