import { Component, OnInit, Input } from '@angular/core';
import { EAgeRange } from '../../../RefEnums/eage-range.enum';

@Component({
  selector: 'app-ages-graph',
  templateUrl: './ages-graph.component.html',
  styleUrls: ['./ages-graph.component.css']
})
export class AgesGraphComponent implements OnInit {

  @Input() insights : any;
  ageInsights = new Array();

  //options  
  view : number[] = [500, 300];
  colorScheme = {
    domain: ['#003f5c', '#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600']
  };
  showXAxis = true;
  showYAxis = true;
  barPadding = 8;

  

  constructor() { }

  ngOnInit() {
    this.insights[0].creatorAudienceAges.forEach((element) => {
      var data = new Object();
      data["name"] = EAgeRange[element["ages"]];
      data["value"] = Number((element["agePct"]).toFixed(1));
      this.ageInsights.push(data);
    });
  }

}
