import { Component, ViewChild } from '@angular/core';
import { UrlInputComponent } from '../common/url-input/url-input.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {
  @ViewChild(UrlInputComponent, { static: true }) urlInput;

  constructor() {
  }

  showNumbers(): void {
    this.urlInput.showNumbers();
  }
}
