import { Component } from '@angular/core';

@Component({
    selector: 'terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.css']
})
/** terms component*/
export class TermsComponent {
    /** terms ctor */
    constructor() {

    }
}