import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-insights-area',
  templateUrl: './insights-area.component.html',
  styleUrls: ['./insights-area.component.css']
})
export class InsightsAreaComponent implements OnInit {

  @Input() insights : any;

  constructor() { }

  ngOnInit() {
  }

}
