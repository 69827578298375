import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PlatformEnum } from '../../models/platform-enum';
import { MAT_DIALOG_DATA, MatGridTile } from '@angular/material';

@Component({
  selector: 'profile-numbers',
  templateUrl: './profile-numbers.component.html',
  styleUrls: ['./profile-numbers.component.css']
})

/** profile-numbers component*/
export class ProfileNumbersComponent {
  public platform: string;
  public numbersMissingTooltip: string;
  public reachTooltip: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.platform = PlatformEnum[data.platform];
    switch (data.platform) {
      case PlatformEnum.Facebook:
        if (data.profile.averageEngagement == 0) {
          this.numbersMissingTooltip = "Facebook Page not found";
          this.reachTooltip = "Number of Friends on facebook profile";
        }
        break;
      case PlatformEnum.Twitter:
        if (data.profile.isPrivateOrProtected) {
          this.numbersMissingTooltip = "Protected Twitter profile";
        }
        break;
      case PlatformEnum.Blog:
        let bio: string = '';
        if (data.numbers.reach > 0)
          bio += data.profile.firstName + ' is managing an active Blog ' + data.url + ' with ' + data.numbers.reachLabel + ' monthly organic reach.\n';
        if (data.numbers.monthlyPageViews > 0)
          bio += 'The blog gets ' + data.numbers.monthlyPageViewsLabel + ' monthly views' + (data.numbers.monthlyUniquePageViews > 0 ? ' including ' + data.numbers.monthlyUniquePageViewsLabel + ' unique views' : '') + '.';
        this.data.profile.bio = bio;
        break;
      default:
        this.numbersMissingTooltip = "";
        break;
    }
  }
}
