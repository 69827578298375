import { Injectable } from '@angular/core';
import { AuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { MatDialog } from '@angular/material';
import { ModalData } from '../models/modal-data';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { PlatformEnum } from '../models/platform-enum';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constants';

@Injectable()
export class FacebookService {
  constructor(
    private dialogService: MatDialog,
    private authService: AuthService,
    private http : HttpClient
  ) {
    this.authService.authState.subscribe(
      (user: SocialUser) => {
        this.userInfo.next(user);
      });
  }

  public userInfo = new BehaviorSubject(null);
  private modalData: ModalData;

  showConnectDialog(): void {
    this.modalData = new ModalData(
      "Connect Facebook Account",
      "We need to ensure that you're trying to access your own facebook page.",
      "Connect With Facebook"
    );
    this.modalData.platform = PlatformEnum.Facebook;

    this.modalData.buttonPositiveHandler = () => {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    };

    this.dialogService.open(ConfirmationDialogComponent, {
      autoFocus: false,
      data: this.modalData
    });
  }

  signOut() {
    if (this.userInfo.value) {
      this.userInfo.next(null);
      return this.authService.signOut();
    }
    else
      return new Promise<any>((resolve, reject) => { });
  }
}
