export enum ECountryNames {
    unassigned = 0,
    Afghanistan = 1,
    Albania = 2,
    Algeria = 3,
    American_Samoa = 4,
    Andorra = 5,
    Angola = 6,
    Anguilla = 7,
    Antarctica = 8,
    Antigua_and_Barbuda = 9,
    Argentina = 10,
    Armenia = 11,
    Aruba = 12,
    Australia = 13,
    Austria = 14,
    Azerbaijan = 15,
    Bahamas = 16,
    Bahrain = 17,
    Bangladesh = 18,
    Barbados = 19,
    Belarus = 20,
    Belgium = 21,
    Belize = 22,
    Benin = 23,
    Bermuda = 24,
    Bhutan = 25,
    Bolivia = 26,
    'Bosnia and Herzegowina' = 27,
    Botswana = 28,
    Bouvet_Island = 29,
    Brazil = 30,
    'British Indian Ocean Territory' = 31,
    'Brunei Darussalam' = 32,
    Bulgaria = 33,
    Burkina_Faso = 34,
    Burundi = 35,
    Cambodia = 36,
    Cameroon = 37,
    Canada = 38,
    Cape_Verde = 39,
    Cayman_Islands = 40,
    Central_African_Republic = 41,
    Chad = 42,
    Chile = 43,
    China = 44,
    Christmas_Island = 45,
    Cocos_Islands = 46,
    Colombia = 47,
    Comoros = 48,
    Congo = 49,
    Cook_Islands = 50,
    Costa_Rica = 51,
    Croatia = 52,
    Cuba = 53,
    Cyprus = 54,
    Czech_Republic = 55,
    Denmark = 56,
    Djibouti = 57,
    Dominica = 58,
    Dominican_Republic = 59,
    Timor_Leste = 60,
    Ecuador = 61,
    Egypt = 62,
    El_Salvador = 63,
    Equatorial_Guinea = 64,
    Eritrea = 65,
    Estonia = 66,
    Ethiopia = 67,
    Falkland_Islands = 68,
    Faroe_Islands = 69,
    Fiji = 70,
    Finland = 71,
    France = 72,
    French_Guiana = 73,
    French_Polynesia = 74,
    French_Southern_Territories = 75,
    Gabon = 76,
    Gambia = 77,
    Georgia = 78,
    Germany = 79,
    Ghana = 80,
    Gibraltar = 81,
    Greece = 82,
    Greenland = 83,
    Grenada = 84,
    Guadeloupe = 85,
    Guam = 86,
    Guatemala = 87,
    Guinea = 88,
    Guinea_bissau = 89,
    Guyana = 90,
    Haiti = 91,
    Heard_and_McDonald_Islands = 92,
    Honduras = 93,
    Hong_Kong = 94,
    Hungary = 95,
    Iceland = 96,
    India = 97,
    Indonesia = 98,
    Iran = 99,
    Iraq = 100,
    Ireland = 101,
    Israel = 102,
    Italy = 103,
    Jamaica = 104,
    Japan = 105,
    Jordan = 106,
    Kazakhstan = 107,
    Kenya = 108,
    Kiribati = 109,
    Korea = 110,
    South_Korea = 111,
    Kuwait = 112,
    Kyrgyzstan = 113,
    Lao = 114,
    Latvia = 115,
    Lebanon = 116,
    Lesotho = 117,
    Liberia = 118,
    Libya = 119,
    Liechtenstein = 120,
    Lithuania = 121,
    Luxembourg = 122,
    Macao = 123,
    Macedonia = 124,
    Madagascar = 125,
    Malawi = 126,
    Malaysia = 127,
    Maldives = 128,
    Mali = 129,
    Malta = 130,
    Marshall_Islands = 131,
    Martinique = 132,
    Mauritania = 133,
    Mauritius = 134,
    Mayotte = 135,
    Mexico = 136,
    Micronesia = 137,
    Moldova = 138,
    Monaco = 139,
    Mongolia = 140,
    Montserrat = 141,
    Morocco = 142,
    Mozambique = 143,
    Myanmar = 144,
    Namibia = 145,
    Nauru = 146,
    Nepal = 147,
    Netherlands = 148,
    Netherlands_Antilles = 149,
    New_Caledonia = 150,
    New_Zealand = 151,
    Nicaragua = 152,
    Niger = 153,
    Nigeria = 154,
    Niue = 155,
    Norfolk_Island = 156,
    Northern_Mariana_Islands = 157,
    Norway = 158,
    Oman = 159,
    Pakistan = 160,
    Palau = 161,
    Panama = 162,
    Papua_New_Guinea = 163,
    Paraguay = 164,
    Peru = 165,
    Philippines = 166,
    Pitcairn = 167,
    Poland = 168,
    Portugal = 169,
    Puerto_Rico = 170,
    Qatar = 171,
    Reunion = 172,
    Romania = 173,
    Russian_Federation = 174,
    Rwanda = 175,
    Saint_Kitts_and_Nevis = 176,
    Saint_Lucia = 177,
    Saint_Vincent_and_the_Grenadines = 178,
    Samoa = 179,
    San_Marino = 180,
    Sao_Tome_and_Principe = 181,
    Saudi_Arabia = 182,
    Senegal = 183,
    Seychelles = 184,
    Sierra_Leone = 185,
    Singapore = 186,
    Slovakia = 187,
    Slovenia = 188,
    Solomon_Islands = 189,
    Somalia = 190,
    'South Africa' = 191,
    South_Georgia_and_the_South_Sandwich_Islands = 192,
    Spain = 193,
    Sri_Lanka = 194,
    Saint_Helena = 195,
    St_Pierre_and_Miquelon = 196,
    Sudan = 197,
    Suriname = 198,
    Svalbard_and_Jan_Mayen_Islands = 199,
    Swaziland = 200,
    Sweden = 201,
    Switzerland = 202,
    Syrian_Arab_Republic = 203,
    Taiwan = 204,
    Tajikistan = 205,
    'United Republic of Tanzania' = 206,
    Thailand = 207,
    Togo = 208,
    Tokelau = 209,
    Tonga = 210,
    Trinidad_and_Tobago = 211,
    Tunisia = 212,
    Turkey = 213,
    Turkmenistan = 214,
    Turks_and_Caicos_Islands = 215,
    Tuvalu = 216,
    Uganda = 217,
    Ukraine = 218,
    'United Arab Emirates' = 219,
    'United Kingdom' = 220,
    'United States' = 221,
    'United States Minor Outlying Islands' = 222,
    Uruguay = 223,
    Uzbekistan = 224,
    Vanuatu = 225,
    'Vatican City' = 226,
    Venezuela = 227,
    Vietnam = 228,
    Virgin_Islands_British = 229,
    Virgin_Islands_US = 230,
    Wallis_and_Futuna_Islands = 231,
    Western_Sahara = 232,
    Yemen = 233,
    Serbia = 234,
    Zambia = 235,
    Zimbabwe = 236,
    Aland_Islands = 237,
    Bonaire_Saint_Eustatius_and_Saba = 238,
    Palestine = 239,
    Montenegro = 240,
    Guernsey = 241,
    Isle_of_Man = 242,
    Jersey = 243,
    Curaçao = 244,
    Ivory_Coast = 245,
    Kosovo = 246,
    Sint_Maarten = 247,
    Serbia_and_Montenegro = 248,
    Saint_Barthelemy = 249,
    Democratic_Republic_of_the_Congo = 250,
    Saint_Martin = 251,
    South_Sudan = 252
}
