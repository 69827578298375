import { NgModule } from '@angular/core';
import { MatButtonModule, MatInputModule, MatDialog, MatDialogRef, MatDialogModule, MatCheckboxModule, MatDividerModule, MatTooltipModule, MatGridListModule, MatIconModule, MatCardModule, MatFormFieldModule, MatAutocompleteModule, MatStepperModule } from '@angular/material'

const material = [
  MatButtonModule,
  MatInputModule,
  MatDialogModule,
  MatCheckboxModule,
  MatDividerModule,
  MatTooltipModule,
  MatGridListModule,
  MatIconModule,
  MatCardModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatStepperModule
]

@NgModule({
  imports: [material],
  exports: [material]
})
export class MaterialModule { }
