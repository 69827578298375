import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { CommonUtilService } from '../../../services/common-util.service';

@Component({
  selector: 'instagram-accounts',
  templateUrl: './instagram-accounts.component.html',
  styleUrls: ['./instagram-accounts.component.css']
})
/** google-analytics-accounts component*/
export class InstagramAccountsComponent {
  selectAccount = new FormControl();
  account: BehaviorSubject<any> = new BehaviorSubject(null);
  accounts: Array<any>;
  filteredAccounts: Observable<Array<any>>;

  constructor(
    private http: HttpClient,
    public commonUtil: CommonUtilService,
    public dialogRef: MatDialogRef<InstagramAccountsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.accounts = data;
    // this.manageAccountsDropdown();
  }

  // manageAccountsDropdown() {
  //   this.filteredAccounts = this.selectAccount.valueChanges
  //     .pipe(
  //       startWith(''),
  //       map(value => typeof value === 'string' ? value : value.name),
  //       map(name => name ? this._filterAccount(name) : this.accounts.slice())
  //     );
  // }

  // private _filterAccount(value: string): Array<any> {
  //   if (value && value != '') {
  //     const filterValue = value.toLowerCase();
  //     return this.accounts.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
  //   }
  //   return this.accounts;
  // }

  // onConfirm(): void {
  //   if (this.selectAccount.value) {
  //     this.account.next((this.selectAccount.value));
  //   }
  // }
}
