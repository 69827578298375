import { Component, OnInit, Input } from '@angular/core';
import { ECountryNames } from 'src/app/RefEnums/ecountry-names.enum';

@Component({
  selector: 'app-countries-graph',
  templateUrl: './countries-graph.component.html',
  styleUrls: ['./countries-graph.component.css']
})
export class CountriesGraphComponent implements OnInit {

  @Input() insights : any[];
  countryInsights = new Array();

  view : any[] = [300, 300];

  //options
  colorScheme = {
    domain: ['#003f5c', '#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600']
  };
  constructor() { 
    
  }

  ngOnInit() {
    this.insights[0].creatorAudienceCountry.forEach((element)=>{
      var data = new Object();
      data["name"] = ECountryNames[element["countries"]];
      data["value"] = Number((element["countryPct"]).toFixed(1));
      this.countryInsights.push(data);
    });
  }

}
