import { Injectable } from '@angular/core';
import { AuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { MatDialog } from '@angular/material';
import { ModalData } from '../models/modal-data';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { PlatformEnum } from '../models/platform-enum';
import { BehaviorSubject } from 'rxjs';
import { GoogleAnalyticsAccountsComponent } from '../common/confirmation-dialog/google-analytics-accounts/google-analytics-accounts.component';
import { GoogleUser } from '../models/google-user';

@Injectable()
export class GoogleService {
  constructor(
    private dialogService: MatDialog,
    private authService: AuthService
  ) {
    this.authService.authState.subscribe(
      (user: SocialUser) => {
        this.userInfo.next(user);
      });
  }

  public userInfo = new BehaviorSubject(null);
  public viewId = new BehaviorSubject('');
  private modalData: ModalData;

  showConnectDialog(): void {
    this.modalData = new ModalData(
      "Connect Google Analytics",
      "We need to ensure that you're trying to access your own blog/website Google Analytics traffic.",
      "Connect With Google"
    );
    this.modalData.platform = PlatformEnum.Blog;

    this.modalData.buttonPositiveHandler = () => {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    };

    this.dialogService.open(ConfirmationDialogComponent, {
      autoFocus: false,
      data: this.modalData
    });
  }

  showAccountDialog(user: GoogleUser, url: string): void {
    let dialogRef = this.dialogService.open(
      GoogleAnalyticsAccountsComponent, {
        data: {
          user: user,
          url: url
        }
      });
    dialogRef.componentInstance.viewId.subscribe((vId: string) => {
      if (vId != '') {
        this.viewId.next(vId);
      }
    });
  }

  signOut() {
    if (this.userInfo.value) {
      this.userInfo.next(null);
      return this.authService.signOut();
    }
    else
      return new Promise<any>((resolve, reject) => { });
  }
}
