import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { MatDialog } from '@angular/material';
import { InstagramAccountsComponent } from 'src/app/common/confirmation-dialog/instagram-accounts/instagram-accounts.component';
import { InstagramService } from 'src/app/services/instagram.service';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard.component';
import { ESocialPlatforms } from 'src/app/RefEnums/esocial-platforms.enum';
import { BehaviorSubject } from 'rxjs';
import { DisconnectInstagramAccountComponent } from 'src/app/common/confirmation-dialog/instagram-accounts/disconnect-instagram-account/disconnect-instagram-account.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-social-connect',
  templateUrl: './social-connect.component.html',
  styleUrls: ['./social-connect.component.css']
})
export class SocialConnectComponent implements OnInit, OnChanges {
  faTimes = faTimes;
  @Input() channels: any;
  youtubeConnected: Boolean = false;
  blogConnected: Boolean = false;
  twitterConnected: Boolean = false;
  instagramConnected: Boolean = false;
  facebookConnected: Boolean = false;
  constructor(
    private authService: AuthService,
    private dialogService: MatDialog,
    private instaService: InstagramService,
    private router: Router,
    private dashboardComponent: DashboardComponent,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnChanges() {
    if (!!this.channels) {
      this.channels.forEach((element) => {
        if (element.length == 0) {
          this.youtubeConnected = false;
          this.blogConnected = false;
          this.twitterConnected = false;
          this.instagramConnected = false;
          this.facebookConnected = false;
        }
        else {
          if (element.platform == ESocialPlatforms.Instagram) {
            this.instagramConnected = true;
          }
          else if (element.platform == ESocialPlatforms.Youtube) {
            this.youtubeConnected = true;
          }
          else if (element.platform == ESocialPlatforms.Blog) {
            this.blogConnected = true;
          }
          else if (element.platform == ESocialPlatforms.Twitter) {
            this.twitterConnected = true;
          }
          else if (element.platform == ESocialPlatforms.Facebook) {
            this.facebookConnected = true;
          }
        }
      });
    }
  }

  ngOnInit() {
    console.log(this.channels);
    this.channels.forEach((element) => {
      if (element.length == 0) {
        this.youtubeConnected = false;
        this.blogConnected = false;
        this.twitterConnected = false;
        this.instagramConnected = false;
        this.facebookConnected = false;
      }
      else {
        if (element.platform == ESocialPlatforms.Instagram) {
          this.instagramConnected = true;
        }
        else if (element.platform == ESocialPlatforms.Youtube) {
          this.youtubeConnected = true;
        }
        else if (element.platform == ESocialPlatforms.Blog) {
          this.blogConnected = true;
        }
        else if (element.platform == ESocialPlatforms.Twitter) {
          this.twitterConnected = true;
        }
        else if (element.platform == ESocialPlatforms.Facebook) {
          this.facebookConnected = true;
        }
      }
    });
  }

  public connectInstagramAccount(): void {
    if (this.instagramConnected == false) {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((SocialUser) => {
        return new Promise((response, reject) => {
          response(SocialUser);
        })
      })
        .then((SocialUser: any) => {
          this.spinner.show("dashboardSpinner");
          var instagramAccounts = new Array();
          var access_token = SocialUser.authToken;
          this.instaService.getInstagramAccountsOfUser(SocialUser)
            .subscribe((data: any) => {
              data.data.map((element) => {
                //if (element.hasOwnProperty("instagram_business_account")) {
                //  instagramAccounts.push(element);
                //  instagramAccounts[instagramAccounts.length-1]["access_token"] = access_token;
                //}
              });
              //if (instagramAccounts.length > 1) {
              //  this.dialogService.open(InstagramAccountsComponent, { width: '500px', data: instagramAccounts })
              //}
              //else {
              var instagramUserinfo = {
                "ScreenNameResponse": data["data"][0]["id"],
                "Username": data["data"][0]["name"],
                "AccessToken": data["data"][0]["access_token"]
              };
              this.instaService.addInstagramAccount(instagramUserinfo).subscribe(() => {
                this.spinner.hide("dashboardSpinner");
                this.spinner.show('dashboardSpinner');
                this.dashboardComponent.ngOnInit();
                this.router.navigate["/dashboard"];
              })
              //}
            });
        })
    }
    else {
      const dialogRef = this.dialogService.open(DisconnectInstagramAccountComponent);
    }

  }

}
