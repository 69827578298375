export const Constants = {
  NUMBERS_SHOWING: "Are numbers being shown",
  ACTIVE_PLATFORM: "Platform Selected",
  VALID_URL: "Is URL Valid",
  MINIMUM_PASSWORD_LENGTH: 6,
  GOOGLE_OAUTH_CLIENTID: "974346517316-papofpo5bae731p2m291b8v7g6d410ul.apps.googleusercontent.com",
  GOOGLE_LOGIN_OPTIONS: {
    scope: 'profile email openid https://www.googleapis.com/auth/analytics.manage.users.readonly https://www.googleapis.com/auth/analytics.readonly',
    account_summaries_endpoint: 'https://www.googleapis.com/analytics/v3/management/accountSummaries',
    analytics_endpoint: 'https://www.googleapis.com/analytics/v3/data/ga'
  },
  FB_AUTH_APPID: "1198803081263795",
  FB_LOGIN_OPTIONS: {
    scope: 'email,read_insights,openid,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_manage_metadata,public_profile,business_management',
    return_scopes: true,
    graph_api_url: "https://graph.facebook.com/v9.0/"
  }
};
