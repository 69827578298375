import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { Constants } from '../../../constants';
import { GoogleUser } from '../../../models/google-user';
import { GoogleAnalyticsView } from '../../../models/ga-view';
import { GoogleAnalyticsAccount } from '../../../models/ga-account';
import { GoogleAnalyticsProperty } from '../../../models/ga-property';
import { CommonUtilService } from '../../../services/common-util.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog.component';

@Component({
  selector: 'google-analytics-accounts',
  templateUrl: './google-analytics-accounts.component.html',
  styleUrls: ['./google-analytics-accounts.component.css']
})
/** google-analytics-accounts component*/
export class GoogleAnalyticsAccountsComponent implements OnInit, OnDestroy {
  private inputUrl: URL;
  private user: GoogleUser;
  accounts: GoogleAnalyticsAccount[];
  selectAccount = new FormControl();
  selectProperty = new FormControl();
  selectView = new FormControl();
  filteredAccounts: Observable<Array<GoogleAnalyticsAccount>>;
  filteredProperties: Observable<Array<GoogleAnalyticsProperty>>;
  filteredViews: Observable<Array<GoogleAnalyticsView>>;
  viewId: BehaviorSubject<string> = new BehaviorSubject('');

  /** google-analytics-accounts ctor */
  constructor(
    private http: HttpClient,
    private dialogService: MatDialog,
    private commonUtil: CommonUtilService,
    public dialogRef: MatDialogRef<GoogleAnalyticsAccountsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.inputUrl = new URL(this.commonUtil.sanitizeUrl(data.url));
    this.user = data.user;
    this.getGoogleAnalyticsAccounts(this.user.authToken);
  }

  ngOnInit() {
    this.manageDropdownEvents();
  }

  manageDropdownEvents(): void {
    this.filteredAccounts = this.selectAccount.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterAccount(name) : (this.accounts ? this.accounts.slice() : []))
      );

    this.filteredProperties = this.selectProperty.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterProperty(name) : (this.selectAccount.value ? (this.selectAccount.value as GoogleAnalyticsAccount).properties.slice() : []))
      );

    this.filteredViews = this.selectView.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterView(name) : (this.selectProperty.value ? (this.selectProperty.value as GoogleAnalyticsProperty).views.slice() : []))
      );
  }

  private _filterAccount(value: string): GoogleAnalyticsAccount[] {
    if (value && value != '') {
      const filterValue = value.toLowerCase();
      return this.accounts.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
    }
    return this.accounts;
  }

  private _filterProperty(value: string): GoogleAnalyticsProperty[] {
    if (value && value != '') {
      const filterValue = value.toLowerCase();
      return this.accounts.filter(acc => acc.id == (this.selectAccount.value as GoogleAnalyticsAccount).id)[0].properties.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
    }
    return this.accounts.filter(acc => this.selectAccount.value == null || acc.id == (this.selectAccount.value as GoogleAnalyticsAccount).id)[0].properties;
  }

  private _filterView(value: string): GoogleAnalyticsView[] {
    if (value && value != '') {
      const filterValue = value.toLowerCase();
      return this.accounts.filter(acc => acc.id == (this.selectAccount.value as GoogleAnalyticsAccount).id)[0].properties.filter(prop => this.selectProperty.value == null || prop.id == (this.selectProperty.value as GoogleAnalyticsProperty).id)[0].views.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
    }
    return this.accounts.filter(acc => this.selectAccount.value == null || acc.id == (this.selectAccount.value as GoogleAnalyticsAccount).id)[0].properties.filter(prop => this.selectProperty.value == null || prop.id == (this.selectProperty.value as GoogleAnalyticsProperty).id)[0].views;
  }

  resetAccount() {
    this.selectAccount.reset();
    this.manageDropdownEvents();
  }

  resetProperty() {
    this.selectProperty.reset();
    this.manageDropdownEvents();
  }

  resetView() {
    this.selectView.reset();
    this.manageDropdownEvents();
  }

  getGoogleAnalyticsAccounts(authToken: string) {
    if (authToken != '') {
      var url = Constants.GOOGLE_LOGIN_OPTIONS.account_summaries_endpoint;
      if (this.commonUtil.validateUrl(url)) {
        this.http.get(url, {
          params: {
            "access_token": authToken
          }
        }).subscribe(response => {
          this.accounts = new Array<GoogleAnalyticsAccount>();
          if (response["items"] && response["items"].length > 0) {
            (response["items"] as Array<any>).forEach(item => {
              let gaAccount = new GoogleAnalyticsAccount();
              gaAccount.id = item["id"].toString();
              gaAccount.name = item["name"].toString();
              if (item["webProperties"] && item["webProperties"].length > 0) {
                gaAccount.properties = new Array<GoogleAnalyticsProperty>();
                (item["webProperties"] as Array<any>).forEach(prop => {
                  let property = new GoogleAnalyticsProperty();
                  property.id = prop["id"].toString();
                  property.name = prop["name"].toString();
                  let webUrl = new URL(prop["websiteUrl"].toString());
                  if (webUrl.hostname == this.inputUrl.hostname) {
                    property.url = prop["websiteUrl"].toString();
                    if (prop["profiles"] && prop["profiles"].length > 0) {
                      property.views = new Array<GoogleAnalyticsView>();
                      (prop["profiles"] as Array<any>).forEach(v => {
                        let view = new GoogleAnalyticsView();
                        view.id = v["id"].toString();
                        view.name = v["name"].toString();
                        view.type = v["type"].toString();
                        property.views.push(view);
                      });
                    }
                    gaAccount.properties.push(property);
                  }
                });
              }
              if (gaAccount.properties && gaAccount.properties.length > 0) {
                this.accounts.push(gaAccount);
              } else {
                let confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
                  data: {
                    header: "Error",
                    message: "Unable to find any Google Analytics Properties matching the URL you entered.",
                    buttonPositive: "Okay",
                    buttonPositiveHandler: () => {
                      this.dialogService.closeAll();
                    }
                  }
                });
              }
            });
          } else {
            let confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
              data: {
                header: "Error",
                message: "Unable to find any Google Analytics Properties related to logged in account.",
                buttonPositive: "Okay",
                buttonPositiveHandler: () => {
                  this.dialogService.closeAll();
                }
              }
            });
          }
        }, error => {
          let confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
              header: "Error",
              message: "An error occurred fetching Google Analytics Account Summaries. Contact Tech Support for more details.",
              buttonPositive: "Okay",
              buttonPositiveHandler: () => {
                this.dialogService.closeAll();
              }
            }
          });
        }
        );
      }
    }
  }

  onConfirm(): void {
    if (this.selectView.value) {
      this.viewId.next((this.selectView.value as GoogleAnalyticsView).id);
    }
    else {
      alert('An error occured while submitting Google Analytics View Id');
    }
    this.dialogService.closeAll();
  }

  ngOnDestroy() {
    this.viewId.unsubscribe();
  }
}
