import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl , Validators} from '@angular/forms';
import { UserAuthService } from '../services/user-auth.service';
import {SignupDTO} from '../models/signup-dto';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector: 'signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})
/** signup component*/
export class SignupComponent implements OnInit{
    signupForm : FormGroup;
    constructor(
        private userAuthService : UserAuthService,
        private router : Router,
        private fb : FormBuilder,
        private spinner : NgxSpinnerService
        ){}

    ngOnInit(){
        this.signupForm = this.fb.group({
            FullName : new FormControl('', [Validators.required]),
            Username : new FormControl('', [Validators.required]),
            PhoneNumber : new FormControl('', [Validators.maxLength(13), Validators.minLength(10)]),
            Email : new FormControl('', [Validators.email]),
            Password : new FormControl('', [Validators.required]),
            ConfirmPassword : new FormControl('', [Validators.required])
        });
    }

    registerNewUser(){
       var user = {
        FullName : this.signupForm.value.FullName,
        PhoneNumber : this.signupForm.value.PhoneNumber,
        Username : this.signupForm.value.Username,
        Email : this.signupForm.value.Email,
        Password : this.signupForm.value.Password
       };
       this.userAuthService.userSignup(user).subscribe((response) => {
           this.spinner.show('signupSpinner');
           if(response.status == 200  && response.body == true){
            this.spinner.hide('signupSpinner');
            this.router.navigate(['/']);
           }
       },
       (error)=>{
           alert('There is some error signing up');
       });
    }
}
