import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalData } from '../../models/modal-data';
import { PlatformEnum } from '../../models/platform-enum';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
/** confirmation-dialog component*/
export class ConfirmationDialogComponent {
  /** confirmation-dialog ctor */
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ModalData) {
    switch (this.data.platform) {
      case PlatformEnum.Facebook:
        this.additionalClass = "facebook";
        this.buttonIcon = ['fab', 'facebook-square'];
        break;
      case PlatformEnum.Blog:
        this.additionalClass = "google";
        this.buttonIcon = ['fab', 'google-plus-square'];
        break;
      case PlatformEnum.Instagram:
        this.additionalClass = "instagram";
        this.buttonIcon = ['fab', 'instagram'];
        break;
      default:
        this.additionalClass = "";
    }
  }

  additionalClass: string = "";
  buttonIcon: any = [];

  onNegativeClick(): void {
    this.data.buttonNegativeHandler();
  }

  onPositiveClick(): void {
    this.data.buttonPositiveHandler();
  }
}
