export enum EAgeRange {
    Unassigned = 0,
    'Below 18 yrs' = 1,
    '18 to 24 yrs' = 2,
    '25 to 34 yrs' = 3,
    '35 to 44 yrs' = 4,
    '45 to 54 yrs' = 5,
    '55 to 64 yrs' = 6,
    'Above 65 yrs' = 7
}
