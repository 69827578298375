import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

  constructor(
    public dialogService: MatDialog,
    private sessionService : SessionService,
    private router : Router
    ) {

  }

  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  openLoginDialog() {
    let dialogOpened = this.dialogService.open(LoginDialogComponent, {
      width: '500px'
    });
  }

  checkLoginStatus(){
    //hide the content
    if(this.sessionService.get("token")){
      return true;
    }
    else {
      return false;
    }
  }

  showDashboard(){
    if(this.checkLoginStatus() && (this.router.url == "/" || this.router.url == '/home') ){
      return true;
    }
    else{
      return false;
    }
  }
  showLogout(){
    if(this.checkLoginStatus() && this.router.url == '/dashboard'){
      return true;
    }
    else{
      return false;
    }
  }

  logout(){
    this.sessionService.remove("token");
    this.router.navigate(['/home']);
  }
}
