import { Injectable } from '@angular/core';
import { PlatformEnum } from '../models/platform-enum';
import { SessionService } from './session.service';
import { Constants } from '../constants';
import { ValidationOutput } from '../models/validation-output';
import { PasswordValidation } from '../models/password-validation';

@Injectable()
export class CommonUtilService {
  constructor(private sessionService: SessionService) {
  }

  validateUrl(val: string): boolean {
    if (val.length > 450)
      return false;
    if (val.length) {
      return /^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(val);
    }
    return false;
  }

  sanitizeUrl(val: string): string {
    if (!val.startsWith('http')) {
      val = 'http://' + val;
    }
    return val;
  }

  validateEmail(val: string): boolean {
    var expr = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return expr.test(val);
  }

  validatePassword(pwd: string): PasswordValidation {
    return new PasswordValidation(pwd);
  }

  getHandleFromUrl(url: string): string {
    if (url.charAt(url.length - 1) == "/")
      url = url.substr(0, url.length - 1);

    let components = url.split('/');
    return components[components.length - 1];
  }

  displayFn(option?: any, prop?: string): string {
    return option ? (prop ? option[prop] : option.name) : '';
  }
}
