import { Component, OnInit, Input } from '@angular/core';
import { ELanguageNames } from '../../../RefEnums/elanguage-names.enum';  

@Component({
  selector: 'app-post-language-graph',
  templateUrl: './post-language-graph.component.html',
  styleUrls: ['./post-language-graph.component.css']
})
export class PostLanguageGraphComponent implements OnInit {

  @Input() insights : any;

  languageInsights = new Array();
  view : any[] = [500, 50];
  colorScheme = {
    domain: ['#003f5c', '#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600']
  };

  constructor() { }

  ngOnInit() {
    // data format
    // {
    //   "name": "Language",
    //   "series": [
    //     {
    //       "name": "English",
    //       "value": 82.42
    //     },
    //     {
    //       "name": "Hindi",
    //       "value": 17.57
    //     },
    //     {
    //       "name": "Bengali",
    //       "value": 0.0
    //     }
    //   ]
    // }
    var data = new Object();
    var series = new Array();
    data["name"] = "Language"
    this.insights[0].creatorPostLanguage.forEach((element)=>{
      var seriesData = new Object();
      seriesData["name"] = ELanguageNames[element["languages"]];
      seriesData["value"] = Number((element["languagePct"]).toFixed(1));
      series.push(seriesData);
    });
    data["series"] = series;
    this.languageInsights.push(data);
  }

}
