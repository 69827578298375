import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab, faTwitter, faFacebookSquare, faGooglePlusSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, AuthService, LoginOpt } from "angularx-social-login";
import { NgxTextOverflowClampModule } from "ngx-text-overflow-clamp";
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TermsComponent } from './terms/terms.component';
import { SignupComponent } from './signup/signup.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { FooterComponent } from './common/footer/footer.component';
import { NavMenuComponent } from './home/nav-menu/nav-menu.component';
import { UrlInputComponent } from './common/url-input/url-input.component';
import { LoginDialogComponent } from './home/login-dialog/login-dialog.component';
import { ProfileNumbersComponent } from './home/profile-numbers/profile-numbers.component';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { InstagramAccountsComponent } from './common/confirmation-dialog/instagram-accounts/instagram-accounts.component';
import { GoogleAnalyticsAccountsComponent } from './common/confirmation-dialog/google-analytics-accounts/google-analytics-accounts.component';

import { Constants } from './constants';
import { UserAuthService } from './services/user-auth.service';
import { GoogleService } from './services/google.service';
import { SessionService } from './services/session.service';
import { FacebookService } from './services/facebook.service';
import { InstagramService } from './services/instagram.service';
import { CommonUtilService } from './services/common-util.service';

import { AuthGuardGuard } from './Guard/auth-guard.guard';
import { SecurePageGuardGuard } from './Guard/secure-page-guard.guard';
import { SocialConnectComponent } from './dashboard/social-connect/social-connect.component';
import { CreatorProfileService } from './services/creator-profile.service';
import { InsightsAreaComponent } from './dashboard/insights-area/insights-area.component';
import { CountriesGraphComponent } from './dashboard/insights-area/countries-graph/countries-graph.component';
import { AgesGraphComponent } from './dashboard/insights-area/ages-graph/ages-graph.component';
import { GenderGraphComponent } from './dashboard/insights-area/gender-graph/gender-graph.component';
import { PostLanguageGraphComponent } from './dashboard/insights-area/post-language-graph/post-language-graph.component';
import { InstagramAccessTokenAppComponent } from './instagram-access-token-app/instagram-access-token-app.component';
import { DisconnectInstagramAccountComponent } from './common/confirmation-dialog/instagram-accounts/disconnect-instagram-account/disconnect-instagram-account.component';


const fbLoginOptions: LoginOpt = Constants.FB_LOGIN_OPTIONS;
const googleLoginOptions: LoginOpt = Constants.GOOGLE_LOGIN_OPTIONS;

// adding configuration of 
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(Constants.GOOGLE_OAUTH_CLIENTID, googleLoginOptions)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(Constants.FB_AUTH_APPID, fbLoginOptions)
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    UrlInputComponent,
    ProfileNumbersComponent,
    SignupComponent,
    LoginDialogComponent,
    FooterComponent,
    ContactComponent,
    TermsComponent,
    PrivacyComponent,
    ConfirmationDialogComponent,
    GoogleAnalyticsAccountsComponent,
    InstagramAccountsComponent,
    DashboardComponent,
    SocialConnectComponent,
    InsightsAreaComponent,
    CountriesGraphComponent,
    AgesGraphComponent,
    GenderGraphComponent,
    PostLanguageGraphComponent,
    InstagramAccessTokenAppComponent,
    DisconnectInstagramAccountComponent
  ],
  entryComponents: [
    LoginDialogComponent,
    ProfileNumbersComponent,
    ContactComponent,
    ConfirmationDialogComponent,
    GoogleAnalyticsAccountsComponent,
    InstagramAccountsComponent,
    DisconnectInstagramAccountComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    MaterialModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgxTextOverflowClampModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SocialLoginModule,
    NgxChartsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'signup', component: SignupComponent, canActivate: [SecurePageGuardGuard] },
      { path: 'contact-us', component: ContactComponent },
      { path: 'terms-of-use', component: TermsComponent },
      { path: 'privacy-policy', component: PrivacyComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardGuard] },
      { path: 'InstagramRedirectUrl', component: InstagramAccessTokenAppComponent }
    ])
  ],
  providers: [
    AuthService,
    {
      provide: AuthServiceConfig,
      useFactory: () => config
    },
    GoogleService,
    SessionService,
    FacebookService,
    InstagramService,
    CommonUtilService,
    UserAuthService,
    CreatorProfileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Add  icon to the library for convenient access in other components
    library.add(faTwitter, faFacebookSquare, faGooglePlusSquare, faInstagram);
  }
}
