import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { SessionService } from 'src/app/services/session.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent {
  username = '';
  password = '';
  loginTooltip = '';
  rememberMe = false;


  constructor(
    private router : Router,
    private dialogService: MatDialog,
    private userAuthService : UserAuthService,
    private sessionService : SessionService,
    private spinner : NgxSpinnerService
    ) {
  }

  redirectToSignup(){
    this.closeAllDialog();
    setTimeout(() => {
      this.router.navigate(['/signup']);
    }, 400);
  }

  closeAllDialog(){
    let dialogClosed = this.dialogService.closeAll();
  }

  loginUser(){
    var login = {
      username : this.username,
      password : this.password
    }
    this.spinner.show('loginSpinner');
    this.userAuthService.userLogin(login).subscribe( (response) => {
      if(response.status == 200 && JSON.parse(JSON.stringify(response.body)).token != null){
        this.sessionService.set("token", JSON.parse(JSON.stringify(response.body)).token);
        this.closeAllDialog();
        this.spinner.hide('loginSpinner');
        this.router.navigate(['/dashboard']);
      }
    },
      (error) =>{
        if(error.status == 401 && error.error == "Username or Password is wrong"){
          this.spinner.hide('loginSpinner');
          alert('Invalid credentials. Please try again!!');
        }
      }
    );
  }

}
