import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAuthService } from '../services/user-auth.service';


//this guard checks whether the user is logged out then redirect the user to signin page.
@Injectable({
  providedIn: 'root'
})
export class SecurePageGuardGuard implements CanActivate {
  constructor(private userAuthService : UserAuthService, private router : Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.userAuthService.isLoggedIn){
      this.router.navigate(["/dashboard"]);
      return false;
    }
    else{
      return true;
    }
  }
  
}
