import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'contact-us',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
/** contact component*/
export class ContactComponent implements OnInit {
  contactForm: FormGroup;

  private readonly contactSendUrl = '/api/contact/send';

  Name() { return this.contactForm.get('Name'); }
  Contact() { return this.contactForm.get('Contact'); }
  Email() { return this.contactForm.get('Email'); }
  Message() { return this.contactForm.get('Message'); }

  constructor(private fb: FormBuilder, private http: HttpClient, private spinner: NgxSpinnerService, private dialogService: MatDialog, public dialogRef: MatDialogRef<ContactComponent>) {
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      Name: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(40)
      ])),
      Contact: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'),
      ])),
      Email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(320),
        Validators.email
      ])),
      Message: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(200),
        Validators.minLength(40)
      ])),
    });
  }

  onSubmitContactDetails(contactModel): void {
    this.spinner.show('requestSpinner');

    this.http.post(this.contactSendUrl, contactModel).subscribe(response => {
      this.dialogRef.close();
      this.dialogService.open(ConfirmationDialogComponent, {
        autoFocus: false,
        width: '400px',
        data: { header: "Done", message: 'Your contact request has been submitted successfully.', buttonPositive: "Okay" }
      });
    }, error => {
      this.dialogRef.close();
      this.dialogService.open(ConfirmationDialogComponent, {
        autoFocus: false,
        width: '400px',
        data: { header: "Error", message: 'An error occurred while saving your details. Please try again later.', buttonPositive: "Okay" }
      });
    });
  }

  errorText(controlName: string): string {
    let control = this.contactForm.get(controlName);
    if (control.valid) {
      return "";
    }
    if (control.errors.maxlength) {
      return "Input too long";
    } else if (control.errors.minlength) {
      return "Input too short";
    } else if (control.errors.required) {
      return "Input required";

    } else if (control.errors.email) {
      return "Input email format";
    } else {
      return "Invalid Input";
    }
  }

  length(control: string): number {
    return this.contactForm.get(control).value.length;
  }

}
