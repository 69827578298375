import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class CreatorProfileService {

  constructor(
    private http : HttpClient,
    private sessionService : SessionService
  ) { }

  getCreatorProfile () : Observable<any>{

    const options = {
      headers : new HttpHeaders({
        'Authorization' : 'Bearer ' + this.sessionService.get("token")
      }),
      observe : 'response' as 'body'
    };
    return this.http.get(environment.webApiUrl + '/api/CreatorProfile/Profile', options);
  }

}
